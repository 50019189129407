import { StaticImage } from 'gatsby-plugin-image';

type Props = {
  title: string;
};

export default function FaqItem({ title }: Props) {
  return (
    <div className="flex flex-row w-full items-center justify-between border-dashed border-b-2 py-6">
      <h2 className="text-xl font-bold text-left">{title}</h2>
      <StaticImage
        src="../assets/faq_item.png"
        alt="facebook logo"
        className="transition ease-in-out hover:-translate-y-2 hover:scale-110 duration-300 cursor-pointer w-16 h-16"
      />
    </div>
  );
}
