export interface IFaq {
  title: string;
  description: string;
  hide?: boolean;
}

export const faqLocation: IFaq[] = [
  {
    title: `Mag ik reclame maken rondom het evenement?`,
    description: `Nee dat is niet toegstaan. Indien je reclame wilt maken, neem contact op met de organisatie via: event@leeuwbieroktoberfest.com`,
  },
  {
    title: `Tot hoe laat kan ik binnenkomen?`,
    description: `Enkel op vrijdag- en zaterdagavond is de toegang tot 23:30 uur.`,
  },
  {
    title: `Mag ik eigen eten en drinken meenemen?`,
    description: `Nee, het meenemen van eigen eten en drinken is niet toegestaan. Er zijn voldoende food en drinksstand aanwezig.
    Heeft u een allergie waardoor u uw eigen eten mee moet nemen? Neemt u dan vooraf contact op via event@leeuwbieroktoberfest.com.`,
  },
];

export const faqTravel: IFaq[] = [
  {
    title: `Gratis event vervoer van en naar de Festwiese`,
    description: `
    &#128153&#129293 <b>𝐆𝐑𝐀𝐓𝐈𝐒 𝐇𝐎𝐏 𝐎𝐍 𝐇𝐎𝐏 𝐎𝐅𝐅 𝐁𝐔𝐒</b> &#129293&#128153
      
    <p>Check de informatie voor het weekend hieronder en zorg dat je ruim op tijd aanwezig bent bij één van de opstapplaatsen. De Hop on Hop off bus is voor onze bezoekers gratis! De opstapplaats is te herkennen door het bord met logo van het event.</p>

    🚌 𝐁𝐮𝐬𝐫𝐨𝐮𝐭𝐞 𝟏: Sibbe (start)➡️ Valkenburg ➡️ Houthem ➡️ Berg & Terblijt ➡️ Vilt ➡️ Sibbe (hop off).

    &#128205 Sibbe: Vertrek van Kenkersweg (bij de ingang van de Festwieze)
    &#128205 Valkenburg Centrum: ‘Hop on’ Bushalte op de Wilhelminalaan (rijrichting naar Station)
    &#128205 Valkenburg Station : ‘Hop on’ Bushalte op de Stationsstraat (naast treinstation Valkenburg)
    &#128205 Houtem: ‘Hop on’ ter hoogte van Cafe ‘t witte Paerd  
    &#128205 Berg & Terblijt: ‘Hop on’ Bushalte op de Rijksweg (rijrichting Vilt, bushalte thv de Kerk)
    &#128205 Vilt: ‘Hop on’ Bushalte op de Rijksweg (rijrichting naar Berg & Terblijt)
    &#128205 Sibbe: ‘Hop-off’ Kenkersweg (bij de ingang van de Festwieze)

    🚌 𝐁𝐮𝐬𝐫𝐨𝐮𝐭𝐞 𝟐: Sibbe (start)➡️ Margraten ➡️ Cadier en Keer ➡️ Bemelen ➡️ Sibbe (hop off).

    &#128205 Sibbe: Vertrek van Kenkersweg (bij de ingang van de Festwieze
    &#128205 Margraten: ‘Hop on’ Bushalte op de Eijkerstraat (rijrichting Rijksweg Margraten)
    &#128205 Cadier en Keer: ‘Hop-on’ Bushalte Rijksweg the Jumbo (rijrichting Rijksweg Maastricht)
    &#128205 Bemelen: ‘Hop-on’ Waterput
    &#128205 Sibbe: ‘Hop-off’ Kenkersweg (bij de ingang van de Festwieze)
    `,
    hide: true,
  },
  {
    title: `Gratis event vervoer van en naar de Festwiese 2024`,
    description: `
    &#128153&#129293 <b>𝐆𝐑𝐀𝐓𝐈𝐒 𝐇𝐎𝐏 𝐎𝐍 𝐇𝐎𝐏 𝐎𝐅𝐅 𝐁𝐔𝐒</b> &#129293&#128153

    &#128652 <b>Zaterdag:</b> De Hop-On-Hop-Off bus vertrekt om <b>18:15</b> vanuit Sibbe. De Hop-On-Hop-Off bus zal iedere 30-45 minuten langs iedere ‘Hop on’ locatie rijden, en rijdt tot <b>02:30 uur</b> door. 

    &#128652 <b>Zondag:</b> De Hop-On-Hop-Off bus vertrekt om <b>12:30</b> vanuit Sibbe. De Hop-On-Hop-Off bus zal iedere 30-45 minuten langs iedere ‘Hop on’ locatie rijden, en rijdt tot <b>21:30 uur</b> door.


    <b>Busroute 1:</b> Sibbe(start) &#9193; Valkenburg &#9193; Houthem &#9193; Berg & Terblijt &#9193; Vilt &#9193; Sibbe (hop off).

    &#128205 Sibbe: Vertrek van Kenkersweg (bij de ingang van de Festwieze)
    &#128205 Valkenburg Centrum: ‘Hop on’ Bushalte op de Wilhelminalaan (rijrichting naar Station)
    &#128205 Valkenburg Station : ‘Hop on’ Bushalte op de Stationsstraat (naast treinstation Valkenburg)
    &#128205 Houthem: ‘Hop on’ ter hoogte van Cafe ‘t witte Paerd
    &#128205 Berg & Terblijt: ‘Hop on’ Bushalte op de Rijksweg (rijrichting Vilt, bushalte thv de Kerk)
    &#128205 Vilt: ‘Hop on’ Bushalte op de Rijksweg (rijrichting naar Valkenburg)
    &#128205 Sibbe: ‘Hop-off’ Kenkersweg (bij de ingang van de Festwieze)
 
    <b>Busroute 2:</b> Sibbe(start) &#9193; Margraten &#9193; Cadier en Keer &#9193; Bemelen &#9193; Sibbe (hop off).

    &#128205 Sibbe: Vertrek van Kenkersweg (bij de ingang van de Festwieze)
    &#128205 Margraten: ‘Hop on’ Bushalte op de Eijkerstraat (rijrichting Rijksweg Margraten)
    &#128205 Cadier en Keer: ‘Hop-on’ Bushalte Rijksweg the Jumbo (rijrichting Rijksweg Maastricht)
    &#128205 Bemelen: ‘Hop-on’ Waterput
    &#128205 Sibbe: ‘Hop-off’ Kenkersweg (bij de ingang van de Festwieze)

    `,
    hide: false,
  },
  {
    title: `Gratis event vervoer van en naar de Festwiese`,
    description: `
    De routes voor de Hop on Hop off Bus zullen begin september 2024 bekend gemaakt worden. Let op; deze rijd alleen op zaterdag en zondag. 
    `,
    hide: true,
  },
  {
    title: `Ik kom op de fiets. Is er een fietsenstalling aanwezig?`,
    description: `Ja die is aanwezig.`,
  },
  {
    title: `Ik kom met de auto. Waar moet ik parkeren?`,
    description: `We hebben verschillende bewegwijzering aangebracht rondom het terrein richting de gratis Parking. Volg de borden (P)arking. 
    Parkeren is op eigen risico!`,
  },
  {
    title: `Ik word gebracht. Waar mag ik worden afgezet?`,
    description: `Op de Kiss & Ride kun je worden afgezet en opgehaald. Volg de borden! `,
  },
  {
    title: `Ik kom met openbaarvervoer. Hoe kom ik daar?`,
    description: `Vanuit Valkenburg (Station) gaat een gratis pendelbus. We adviseren dan ook om vanuit Station Valkenburg richting het evenemententerrein te komen. 
    Kom je er niet uit? Gebruik de volgende website: https://9292.nl  

    Navigatieadres: Bemelerweg 8, 6301 BE Valkenburg
    `,
  },
];

export const faqGeneral: IFaq[] = [
  {
    title: `Adres, route en openingstijden?`,
    description: `Navigatieadres parkeerplaats: Bemelerweg 8, 6301 BE Valkenburg. 
    Festivalterrein: Kenkersweg Sibbe (Valkenburg a/d Geul)

    De openingstijden van het terrein en de feesttent:
    Vrijdag 27 september van 19:30 tot 02:00. 
    Zaterdag 28 september: 18:45 tot 02:00. 
    Zondag 29 september: 13:00 tot 21:00.
     `,
  },
  {
    title: `Wat is de leeftijd om binnen te komen?`,
    description: `De minimale leeftijd voor vrijdag- en zaterdagavond is 18 jaar en ouder. Op zondag zijn alle leeftijden welkom. Let op! We hanteren een NIX18 beleid.`,
  },
  {
    title: `Roken?`,
    description: `Zowel het roken van sigaretten als e-sigaretten zijn binnen in de tent niet toegestaan. Dit mag alleen buiten op ons chill plein. `,
  },
  {
    title: `Munten en betalingsmogelijkheden? `,
    description: `Bij ons kun je zowel met de pin als contantgeld betalen. Je koopt je drink- en foodmunten bij één van onze kassa’s op het terrein.
    Let op; gekochte munten worden niet retour genomen. 
    De munten van Knaltoberfest kunnen niet op zaterdag en zondag gebruikt worden.`,
  },
  {
    title: `Mag ik tussentijds naar buiten en weer terug naar binnen?`,
    description: `Op alle dagen krijgt u een polsbandje. Zolang deze om blijft, kan u naar binnen en buiten gaan.`,
  },
  {
    title: `Zero Tolerance`,
    description: `Bij het Leeuw Bier Oktoberfest hanteren we een zero tolerance beleid. Dit betekent dat verboden middelen en wapens verboden zijn. Word je betrapt, dan maken wij een melding bij de politie en is jouw feest helaas voorbij.`,
  },
  {
    title: `Corona richtlijnen`,
    description: `We houden ons ten alle tijden aan de richtlijnen van het RIVM`,
    hide: true,
  },
  {
    title: `Moet ik mijn ticket uitprinten? Of ben je het ticket kwijt?`,
    description: `Het is niet nodig om je ticket uit te printen, maar zorg dat je je ticket goed bewaart in je bestanden op je telefoon, zodat je er altijd bij kunt. Zorg ook dat je telefoon geen krassen op het scherm heeft. Kun je geen geldig ticket overleggen, dan krijg je helaas geen toegang tot het event. Ben je jouw ticket kwijt? Neem contact op met https://www.compoticketing.eu `,
  },
  {
    title: `Overige vragen`,
    description: `Heb je een vraag die hier niet tussenstaat? Mail dan naar: event@leeuwbieroktoberfest.com`,
  },
];
