import { ReactNode } from 'react';

type TitleProps = {
  children: ReactNode;
};

export default function Title({
  children,
  className,
}: TitleProps & Partial<Pick<HTMLElement, 'className'>>) {
  return (
    <h1 className={`text-white text-5xl font-normal ${className}`}>
      {children}
    </h1>
  );
}
