import { StaticImage } from 'gatsby-plugin-image';
import Title from './Title';

export default function Knaltoberfest() {
  return (
    <div
      className="w-full flex justify-center items-center px-4"
      id="knaltoberfest"
    >
      <div className="flex flex-col justify-center items-center lg:w-3/4">
        <Title className="my-16 text-center">Knaltoberfest 2024</Title>
        <div className="flex flex-col items-center">
          <h2 className="text-xl leading-8 text-white text-center max-w-4xl">
            Op vrijdagavond 27 september trappen we af met het Knalfestijn
            tussen de Limburgse Heuvels. Knaltoberfest is een Duitse traditie
            met ‘n eigenwijs KNALFEEST karakter. Zoals we dat in Nederland maar
            al te goed kunnen. Voor meer informatie klik op de button hieronder.
          </h2>
          <a
            href="https://knaltoberfest.nl"
            className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 mt-10 cursor-pointer w-2/3 lg:w-2/3"
            target="blank"
            // onClick={handleMailToClick}
          >
            <StaticImage
              src="../assets/Knaltoberfest_button_info.png"
              alt="Ga naar website knaltoberfest"
              imgClassName="flex"
              placeholder="blurred"
              quality={100}
            />
          </a>
        </div>
      </div>
    </div>
  );
}
