import { StaticImage } from 'gatsby-plugin-image';
import { scrollTo } from '@/utils';
import Socials from './Socials';

export default function Footer() {
  return (
    <div className="lg:h-80 w-screen bg-third flex flex-col-reverse lg:flex-row mt-14 lg: pt-8">
      <div className="flex lg:w-1/3 p-14 lg:p-0  flex-col items-center justify-center">
        <StaticImage
          src="../assets/logo_vdhof.png"
          alt="logo"
          className="h-2/3"
          objectFit="scale-down"
          placeholder="blurred"
        />
        <span className="text-white font-light text-sm">
          © Leeuw Bier OktoberFest
        </span>
      </div>
      <div className="flex flex-col lg:w-1/3 items-center lg:items-start p-14 lg:p-0 px-11">
        <h1 className="my-3">ADRES</h1>
        <h3>Bemelerweg 8, 6301BE</h3>
        <h3>(Valkenburg a/d Geul)</h3>
        <div
          onClick={() =>
            window.open(
              `https://www.google.nl/maps/place/Bemelerweg+8,+6301+BE+Valkenburg/@50.8471296,5.8140211,17z/data=!3m1!4b1!4m5!3m4!1s0x47c0eab805e8b367:0x40e91e558a599cf3!8m2!3d50.8471296!4d5.8162098`,
            )
          }
          className="h-1/2 flex justify-start hover:transition-all duration-300 cursor-pointer mt-3"
        >
          <StaticImage
            src="../assets/locatie.png"
            alt="Adres oktoberfest kenkersweg Sibbe"
            objectFit="scale-down"
            objectPosition="0% 0%"
          />
        </div>
      </div>
      <div className="flex flex-col lg:w-1/3 items-center lg:items-start lg:justify-between">
        <div>
          <div className="flex flex-col items-center lg:items-start">
            <h1 className="my-3">LINKS</h1>
            <h3
              className="hover:text-cyan-600 cursor-pointer"
              onClick={() => scrollTo({ id: `faq` })}
            >
              FAQ
            </h3>
            <h3
              className="hover:text-cyan-600 cursor-pointer"
              onClick={() => scrollTo({ id: `tickets` })}
            >
              Tickets
            </h3>
            {/* <h3 className="hover:text-cyan-600 cursor-pointer">Huisregels</h3> */}
          </div>
          <Socials />
        </div>
        <span className="text-white font-light text-sm mb-10 mt-10">
          Development by{` `}
          <span
            className="text-yellow-400 cursor-pointer hover:text-cyan-600"
            onClick={() => window.open(`https://wowcreators.nl/`)}
          >
            WOW Creators{` `}
          </span>
          &{` `}
          <span
            className="text-yellow-400 cursor-pointer hover:text-cyan-600"
            onClick={() => window.open(`https://mobiletraction.nl`)}
          >
            MobileTraction
          </span>
        </span>
      </div>
    </div>
  );
}
