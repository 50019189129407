import { IFaq } from '@/data/faq';
import FaqItem from './FaqItem';
import Collapsible from 'react-collapsible';
import { LinkItEmail, LinkItUrl } from 'react-linkify-it';
import Parser from 'html-react-parser';

type Props = {
  item: IFaq;
  index: number;
};

export default function FaqCard({ item }: Props) {
  if (item?.hide) {
    return null;
  }

  return (
    <Collapsible trigger={<FaqItem title={item.title} />}>
      <div className="py-6">
        <div className="text-white text-xs font-mono lg:text-lg self-start whitespace-pre-line">
          <LinkItUrl className="text-primary-500 italic">
            <LinkItEmail className="text-primary-500 italic">
              {Parser(item.description)}
            </LinkItEmail>
          </LinkItUrl>
        </div>
      </div>
    </Collapsible>
  );
}
