import { PropsWithChildren } from 'react';
import { useCountdown } from '@/hooks/useCountdown';

type Props = PropsWithChildren<unknown>;

export default function Countdown({ children }: Props) {
  const [days, hours, minutes, seconds] = useCountdown(
    `2022-12-15T19:00:00.000Z`,
  );

  return children

  if (days + hours + minutes + seconds <= 0) {
    return children;
  }

  return (
    <div className="flex flex-col items-center">
      <h1 className="pt-9 pb-3 self-center">Ticketverkoop start over:</h1>
      <div className="grid grid-cols-4 gap-3">
        <div className="flex flex-col items-center p-5 bg-white opacity-75 rounded-full">
          <span className="text-2xl md:text-5xl">{days}</span>
          <span className="text-xs md:text-sm text-primary">dagen</span>
        </div>
        <div className="flex flex-col items-center p-5 bg-white opacity-75 rounded-full text-lg">
          <span className="text-2xl md:text-5xl">{hours}</span>
          <span className="text-xs md:text-sm text-primary">uren</span>
        </div>
        <div className="flex flex-col items-center p-5 bg-white opacity-75 rounded-full text-lg">
          <span className="text-2xl md:text-5xl">{minutes}</span>
          <span className="text-xs md:text-sm text-primary">minuten</span>
        </div>
        <div className="flex flex-col items-center p-5 bg-white opacity-75 rounded-full text-lg">
          <span className="text-2xl md:text-5xl">{seconds}</span>
          <span className="text-xs md:text-sm text-primary">seconden</span>
        </div>
      </div>
    </div>
  );
}
