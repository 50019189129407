import { StaticImage } from 'gatsby-plugin-image';
import Title from './Title';

export default function SponsorSubscribe() {
  function handleMailToClick(event: any) {
    event.preventDefault(); // Prevent the default link behavior
    window.location.href = `mailto:event@leeuwbieroktoberfest.com?subject=Sponsormogelijkheden&body=Vul%20onderstaande%20gegevens%20in%20en%20we%20nemen%20zsm%20contact%20met%20je%20op%20over%20de%20sponsormogelijkheden.%0D%0A%0D%0AVoornaam%3A%0D%0AAchternaam%3A%0D%0ABedrijfsnaam%3A%0D%0ATelefoonnummer%3A%0D%0AE-mailadres%3A`;
  }

  return (
    <div
      id="sponsorship"
      className="w-full flex justify-center items-center px-4"
    >
      <div className="flex flex-col justify-center items-center lg:w-3/4">
        <Title className="my-16">Partners</Title>
        {/* <h2 className="text-xl font-bold text-center">
          Sponsor worden van dit geweldige event? Wij bieden interessante
          mogelijkheden aan. Stuur je gegevens in via onderstaande knop
        </h2> */}

        <div className="flex flex-col items-center">
          {/* <div className="hidden absolute md:block md:h-80 md:w-80 top-30 right-0">
        <StaticImage
          src={'/assets/funny/funny_1.webp'}
          fill
          alt="Funny hehehe"
          className="object-cover"
          priority
        />
      </div> */}

          <h2 className="text-xl leading-8 text-white text-center max-w-4xl">
            Leeuw Bier Oktoberfest kan niet zonder partners. Door deze partners
            kunnen we het event naar een nog hoger niveau tillen. Heb je
            interesse om sponsor/partner te worden van dit geweldige event of
            heb je andere toffe ideeën? We hebben verschillende mogelijkheden
            ook in combinatie met{` `}
            <a href="https://knaltoberfest.nl" target="_blank" rel="noreferrer">
              Knaltoberfest
            </a>
            {` `}
            dat wordt gehouden op dezelfde locatie op vrijdag 27 september.
            Bekijk onze{` `}
            <a
              className="font-light underline hover:text-primary"
              target="_blank"
              rel="noreferrer"
              href="https://indd.adobe.com/view/d63b550d-45e8-4b5b-97ec-bfcadf62a7bd"
            >
              brochure
            </a>
            {` `}
            of meld je meteen aan voor een persoonlijk gesprek.
          </h2>
          {/* GRID START */}
          <>
            <h1 className="mt-12 text-white">Hoofdsponsors</h1>
            <div className="grid grid-cols-1 gap-4 w-full items-center md:grid-cols-12 mt-8">
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://www.opreij.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/JanOpreij.png`}
                  alt="Jan Opreij"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://kallebeske-sittard.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/Kallebeske.png`}
                  alt="Kallebeske Sittard"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://ingendael.com/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/IngendealShots.png`}
                  alt="Ingendael Shots"
                  imgClassName="flex"
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://haacht.com/nl/merken/bieren/pilsener"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/LeeuwBier.png`}
                  alt="Leeuw Bier"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://www.plus.nl/supermarkten/margraten_plus-kleijnen-margraten_306"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/PlusKleijnen.png`}
                  alt="Plus Kleijnen Margraten"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://berhuynen.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src={`../assets/partners/BerHuynen.png`}
                  alt="Ber Huynen"
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://www.demoproductions.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/DemoProductions.png`}
                  alt="Demo Productions"
                  imgClassName="flex"
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://www.hanos.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/Hanos.png`}
                  alt="Demo Productions"
                  imgClassName="flex"
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://mobiletraction.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/MobileTraction.png`}
                  alt="MobileTraction"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://wowcreators.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/WOWCreators.webp`}
                  alt="WOW Creators"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://huurenstuur.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/Huurenstuur.png`}
                  alt="Huur en Stuur"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://maaneventsupport.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/MaanEventSupport.png`}
                  alt="MAAN Event Support"
                  imgClassName="flex"
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://www.friterieoplocatie.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/FriterieAonDeKirk.png`}
                  alt="Friterie Aon de Kirk"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://bessemsdranken.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/Bessems.png`}
                  alt="Bessems Dranken"
                  fill
                />
              </a>

              {/* EINDE HOOFDSPONSOREN */}
            </div>
          </>
          <>
            <h1 className="mt-8 text-white">Gold Sponsors</h1>
            <div className="grid grid-cols-1 gap-4 w-full items-center md:grid-cols-12 mt-8">
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://euregiohr.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/HabetsRoyen.png`}
                  alt="Habets & Royen"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://coumansfotografie.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/Coumans.png`}
                  alt="Coumans fotografie"
                  imgClassName="flex"
                />
              </a>

              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://boomkwekerijrobfrijnts.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/RobFrijnts.png`}
                  alt="Rob Frijnts Boomkwekerij"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://weihenstephaner.de/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/Weihenstephaner.png`}
                  alt="Weihenstephaner"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://www.frissen-groentechniek.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/Frissen.png`}
                  alt="Frissen groentechniek"
                  fill
                />
              </a>
              <a
                className="h-40 flex col-span-2 relative bg-slate-100 rounded-lg"
                href="https://falconfm.nl/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  objectFit="contain"
                  src={`../assets/partners/FalconRadio.png`}
                  alt="Falcon Radio 94.3FM"
                  fill
                />
              </a>

              {/* EINDE GOLD SPONSOREN */}
            </div>
          </>

          {/* <div className="flex flex-col md:flex-row mt-2">
            <Button className="mt-6 md:mr-2 ease-in duration-150">
              <Link href="contact">Aanmelden</Link>
            </Button>

            <Button className="mt-6 md:ml-2 ease-in duration-150">
              <a
                href="https://indd.adobe.com/view/56e38659-5a17-4972-972c-b8a2c0f8bdff"
                target="_blank"
                rel="noreferrer"
              >
                Brochure
              </a>
            </Button>
          </div> */}
        </div>

        {/* Sponsor worden button */}
        <a
          href="#"
          className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 mt-10 cursor-pointer w-2/3 lg:w-2/3"
          onClick={handleMailToClick}
        >
          <StaticImage
            src="../assets/become_partner_button.png"
            alt="Ga naar ticketshop"
            imgClassName="flex"
            placeholder="blurred"
            quality={100}
          />
        </a>
      </div>
    </div>
  );
}
