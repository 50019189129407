import { StaticImage } from 'gatsby-plugin-image';
import MediaQuery from 'react-responsive';

interface Props {
  onTicketButtonPress: () => void;
}

const Logo = ({ onTicketButtonPress }: Props) => {
  return (
    <div
      className="flex flex-col items-center h-screen w-screen bg-transparent"
      id="home"
    >
      <StaticImage
        src="../assets/logo_vdhof.png"
        alt="logo oktoberfest"
        className="self-start h-28 w-28 top-4 left-8 z-10"
        placeholder="blurred"
      />
      <MediaQuery maxWidth={1024}>
        <StaticImage
          src="../assets/logo_frontpage_mobile.png"
          alt="poster mobile"
          className="w-screen lg:-mb-64"
          // imgClassName="flex"
          objectFit="scale-down"
          placeholder="blurred"
        />
      </MediaQuery>
      <MediaQuery minWidth={1024}>
        <StaticImage
          src="../assets/logo_frontpage_1.png"
          alt="poster"
          className="w-screen h-screen mb-10 lg:-mb-16"
          imgClassName="flex"
          objectFit="scale-down"
          placeholder="blurred"
          quality={100}
        />
      </MediaQuery>
      <div
        className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer w-2/3 lg:w-1/3 -mt-44 lg:mt-0"
        onClick={onTicketButtonPress}
      >
        <StaticImage
          src="../assets/tickets_button.png"
          alt="Tickets kopen"
          imgClassName="flex"
          placeholder="blurred"
          quality={100}
        />
      </div>
    </div>
  );
};

export default Logo;
