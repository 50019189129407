// import { FullPage, Slide } from 'react-full-page';
import Logo from '@/components/Logo';
import Sponsors from '@/components/Sponsors';
import FAQ from '@/components/Faq';
import Footer from '@/components/Footer';
import Tickets from '@/components/Tickets';
import { scrollTo } from '@/utils';
import Menu from '@/components/Menu';
import SponsorSubscribe from '@/components/SponsorSubscribe';
import AfterMovie from '@/components/AfterMovie';
import Knaltoberfest from '@/components/Knaltoberfest';
import GibMalGas from '@/components/GibMalGas';
import HossaAmSonntag from '@/components/HossaAmSonntag';
import { Spacer } from '@/components/Spacer';
import CookieBanner from '@/components/cookie-banner/CookieBanner';
import Schedule from '@/components/Schedule';

export default function Home() {
  const scrollToTickets = () => {
    scrollTo({ id: `tickets`, duration: 2000 });
  };

  return (
    <main>
      <div className="bg-background-content flex flex-col">
        <Menu />
        <Logo onTicketButtonPress={scrollToTickets} />
        <Sponsors />
        <AfterMovie />
        <Tickets />
        <Schedule />
        <Knaltoberfest />
        <Spacer />
        <GibMalGas />
        <Spacer />
        <HossaAmSonntag />
        <Spacer />
        <SponsorSubscribe />
        <FAQ />
        <Footer />
        <CookieBanner />
      </div>
    </main>
  );
}
