import { StaticImage } from 'gatsby-plugin-image';
import { Fade } from 'react-awesome-reveal';
import Title from './Title';

export default function AfterMovie() {
  return (
    <div id="aftermovie" className="w-full flex flex-col items-center">
      <Fade duration={1500}>
        <Title className="my-16 text-center">Aftermovie 2023</Title>
        <div className={`w-full relative`}>
          <iframe
            className="absolute top-0 left-0 h-full w-full p-2 md:p-4 "
            src="https://www.youtube-nocookie.com/embed/s1t7ttKvwz8?autoplay=1&mute=1"
          ></iframe>
          <StaticImage
            src="../assets/kader_video.png"
            className="w-full aspect-video pointer-events-none"
            alt="kader"
            objectFit="fill"
          />
        </div>
      </Fade>
    </div>
  );
}
