import { StaticImage } from 'gatsby-plugin-image';
import { forwardRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import Countdown from './Countdown';
import Title from './Title';
import { ENABLE_TICKETSALES } from '@/CONSTANTS';

const Tickets = forwardRef<HTMLDivElement>((_, ref) => {
  const goToTicketshop = () => {
    const url = `https://shop.compoticketing.eu/nl/shop/ticketshop/event/A7A92E41DF85`;
    window.open(url, `_blank`);
  };

  return (
    <Fade triggerOnce>
      <div
        id="tickets"
        ref={ref}
        className="flex flex-col w-screen items-center justify-center px-8"
      >
        <Title className="mt-16">Tickets</Title>

        {ENABLE_TICKETSALES ? (
          <Countdown>
            <div
              className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 mt-10 cursor-pointer w-2/3 lg:w-1/3"
              onClick={goToTicketshop}
            >
              <StaticImage
                src="../assets/to_ticketshop_button.png"
                alt="Ga naar ticketshop"
                imgClassName="flex"
                placeholder="blurred"
                quality={100}
              />
            </div>
          </Countdown>
        ) : (
          <h2 className="text-xl leading-8 text-white text-center max-w-4xl mt-10">
            Door een storing bij onze ticketleverancier is het op dit moment
            niet mogelijk om tickets te kopen. De ticketverkoop word zo spoedig
            mogelijk herstart. Excuses voor het ongemak.
          </h2>
        )}
      </div>
    </Fade>
  );
});

Tickets.displayName = `Tickets`;

export default Tickets;
