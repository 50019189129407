import { StaticImage } from 'gatsby-plugin-image';

export default function Socials() {
  return (
    <div className="flex flex-row mt-4">
      <div
        className=""
        onClick={() =>
          window.open(`https://www.instagram.com/leeuw_bier_oktoberfest/`)
        }
      >
        <StaticImage
          src="../assets/insta_logo.png"
          alt="facebook logo"
          className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer w-12 h-12"
        />
      </div>
      <div
        onClick={() =>
          window.open(`https://www.facebook.com/profile.php?id=100082838247471`)
        }
      >
        <StaticImage
          src="../assets/facebook_logo.png"
          alt="facebook logo"
          className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 cursor-pointer w-12 h-12 ml-3"
        />
      </div>
    </div>
  );
}
