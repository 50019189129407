import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Twirl as Hamburger } from 'hamburger-react';
import { scrollTo } from '@/utils';
import { ENABLE_TICKETSALES } from '@/CONSTANTS';

export default function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  const onItemPress = (toId: string) => {
    setIsOpen(false);

    setTimeout(() => {
      scrollTo({ id: toId, duration: 2000 });
    }, 300);
  };

  return (
    <>
      <div
        className="absolute top-10 right-10 z-50"
        onScroll={() => setIsOpen(false)}
      >
        <Hamburger
          onToggle={() => setIsOpen(!isOpen)}
          color="white"
          toggled={isOpen}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="bg-primary absolute"
            initial={{
              width: `100%`,
              height: `100%`,
              opacity: 0,
              top: 0,
              right: 0,
              zIndex: 9,
            }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
          >
            <div className="flex flex-col w-full h-full items-center justify-center">
              <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`home`)}
              >
                Home
              </button>
              <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`aftermovie`)}
              >
                Aftermovie
              </button>
              {ENABLE_TICKETSALES && (
                <button
                  className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                  onClick={() => onItemPress(`tickets`)}
                >
                  Tickets
                </button>
              )}
              <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`schedule`)}
              >
                Programma
              </button>
              <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`sponsorship`)}
              >
                Partners
              </button>
              <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`knaltoberfest`)}
              >
                Knaltoberfest
              </button>
              <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`gib-mal-gas`)}
              >
                Gib Mal Gas
              </button>
              <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`hossa-am-sonntag`)}
              >
                Hossa Am Sonntag
              </button>
              <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`faq`)}
              >
                FAQ
              </button>
              {/* <button
                className="text-white text-4xl md:text-5xl mb-5 hover:text-black"
                onClick={() => onItemPress(`rules`)}
              >
                Huisregels
              </button> */}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
