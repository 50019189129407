import { StaticImage } from 'gatsby-plugin-image';
import { Fade } from 'react-awesome-reveal';
import Title from './Title';

export default function Schedule() {
  return (
    <div
      className="w-screen flex flex-col bg-transparent items-center py-24"
      id="schedule"
    >
      <Title>Programma</Title>
      <div className="flex flex-col lg:flex-row content-around mt-16 lg:px-20">
        <div className="flex flex-col items-center lg:mr-4">
          <Fade triggerOnce>
            <div className="flex flex-col items-center">
              <StaticImage
                src="../assets/schedule_27.png"
                alt="Vrijdag 27 september"
                className="w-2/3 lg:w-1/2 h-2/6"
                placeholder="blurred"
                objectFit="scale-down"
              />
              <h1 className="my-4">De beuk erin</h1>
            </div>
          </Fade>
          <Fade triggerOnce>
            <div className="grid grid-cols-2 gap-2 px-6 lg:grid-cols-3 lg:gap-6">
              <StaticImage
                src="../assets/artiesten/vrijdag/Artiest_1.webp"
                alt="Paul Elstak"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/vrijdag/Artiest_2.webp"
                alt="Freddy Moreira"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/vrijdag/Artiest_3.webp"
                alt="Otto Wunderbar"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/vrijdag/Artiest_4.webp"
                alt="Erwin"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/vrijdag/Artiest_5.webp"
                alt="Robert Pater"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/vrijdag/Artiest_6.webp"
                alt="Frans Theunisz"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/vrijdag/Artiest_7.webp"
                alt="Remco Gaiteros"
                placeholder="blurred"
              />
            </div>
          </Fade>
        </div>
        <div className="flex flex-col items-center lg:mr-4">
          <Fade triggerOnce>
            <div className="flex flex-col items-center">
              <StaticImage
                src="../assets/schedule_28.png"
                alt="Zaterdag 28 september"
                className="w-2/3 lg:w-1/2 h-2/6"
                placeholder="blurred"
                objectFit="scale-down"
              />
              <h1 className="my-4">Gib mal Gas Party</h1>
            </div>
          </Fade>
          <Fade triggerOnce>
            <div className="grid grid-cols-2 gap-2 px-6 lg:grid-cols-3 lg:gap-6">
              <StaticImage
                src="../assets/artiesten/zaterdag/Artiest_1.webp"
                alt="Almklausi"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zaterdag/Artiest_2.webp"
                alt="Dennie Christian"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zaterdag/Artiest_3.webp"
                alt="Sunnseitn"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zaterdag/Artiest_4.webp"
                alt="Rosentaler"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zaterdag/Artiest_5.webp"
                alt="Jennifer Sturm"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zaterdag/Artiest_6.webp"
                alt="DjDirndl"
                placeholder="blurred"
              />
            </div>
          </Fade>
        </div>
        <div className="flex flex-col items-center mt-24 lg:mt-0 lg:ml-4">
          <Fade triggerOnce>
            <div className="flex flex-col items-center">
              <StaticImage
                src="../assets/schedule_29.png"
                alt="Zondag 29 september"
                className="w-2/3 lg:w-1/2 h-2/6"
                placeholder="blurred"
                objectFit="scale-down"
              />
              <h1 className="my-4">Hossa am Sonntag</h1>
            </div>
          </Fade>
          <Fade triggerOnce>
            <div className="grid grid-cols-2 gap-2 px-6 lg:grid-cols-3 lg:gap-6">
              <StaticImage
                src="../assets/artiesten/zondag/Artiest_1.webp"
                alt="Marc Pirscher"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zondag/Artiest_2.webp"
                alt="Freddy Pfister Band"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zondag/Artiest_3.webp"
                alt="Huttenspass"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zondag/Artiest_4.webp"
                alt="Frans Theunisz"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zondag/Artiest_5.webp"
                alt="Brent Slijpen"
                placeholder="blurred"
              />
              <StaticImage
                src="../assets/artiesten/zondag/Artiest_6.webp"
                alt="DJ Frank Bessems"
                placeholder="blurred"
              />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}
