import Title from './Title';

export default function GibMalGas() {
  return (
    <div
      id="gib-mal-gas"
      className="w-full flex justify-center items-center px-4"
    >
      <div className="flex flex-col justify-center items-center lg:w-3/4">
        <Title className="my-16 text-center">Gib Mal Gas</Title>
        <div className="flex flex-col items-center">
          <h2 className="text-xl leading-8 text-white text-center max-w-4xl">
            Gas erop bij &rsquo;Gib Mal Gas&rsquo; op zaterdag 28 september! Een
            avond vol energie van start tot finish. Met een geweldig programma
            belooft deze editie wederom een onvergetelijk festijn te worden!
            Proost op een feestelijke avond! Ein Prosit!
          </h2>
        </div>
      </div>
    </div>
  );
}
