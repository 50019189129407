import Title from './Title';

export default function HossaAmSonntag() {
  return (
    <div
      id="hossa-am-sonntag"
      className="w-full flex justify-center items-center px-4"
    >
      <div className="flex flex-col justify-center items-center lg:w-3/4">
        <Title className="my-16 text-center">Hossa Am Sonntag</Title>
        <div className="flex flex-col items-center">
          <h2 className="text-xl leading-8 text-white text-center max-w-4xl">
            Hossa am Sonntag’ is dé familiedag op het Leeuw Bier Oktoberfest,
            een feest voor jong en oud. Geniet van onvergetelijke optredens,
            heerlijk eten en verrassingen voor de kleintjes. Een dag vol
            gezelligheid, plezier en onvergetelijke momenten op deze prachtige
            locatie aan de rand van Sibbe, vlakbij Valkenburg aan de Geul.
          </h2>
        </div>
      </div>
    </div>
  );
}
