import { faqGeneral, faqLocation, faqTravel } from '@/data/faq';
import { Fade } from 'react-awesome-reveal';
import FaqCard from './FaqCard';

import Title from './Title';

export default function FAQ() {
  return (
    <Fade triggerOnce>
      <div
        id="faq"
        className="w-screen justify-center flex flex-col items-center bg-transparent"
      >
        <div className="h-auto w-screen flex flex-col px-4 lg:w-2/4">
          <div className="flex flex-col items-center">
            <Title className="my-16">FAQ</Title>
            <h1 className="text-3xl">Locatie</h1>
          </div>
          {faqLocation?.map((item, index) => (
            <FaqCard key={item.title} item={item} index={index} />
          ))}
          <h1 className="text-3xl mt-16 self-center">Algemeen</h1>
          {faqGeneral?.map((item, index) => (
            <FaqCard key={item.title} item={item} index={index} />
          ))}
          <h1 className="text-3xl mt-16 self-center">Vervoer</h1>
          {faqTravel?.map((item, index) => (
            <FaqCard key={item.title} item={item} index={index} />
          ))}
        </div>
      </div>
    </Fade>
  );
}
