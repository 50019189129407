import { StaticImage } from 'gatsby-plugin-image';
import { Fade } from 'react-awesome-reveal';

export default function Sponsors() {
  return (
    <Fade duration={1500}>
      <div className="w-screen bg-transparent flex flex-col justify-around items-center">
        <div className="flex flex-col justify-center items-center w-screen px-10 lg:w-1/3 lg:px-0 ">
          <h1 className="text-white text-4xl font-normal ">Jetz, geht los!</h1>
          <h2 className="text-white text-xl font-normal text-center pt-10">
            Welkom bij het Leeuw Bier Oktoberfest 2024 tussen de limburgse
            heuvels.
            <br />
            <br />
            Op vrijdag 27 september tijdens (Knaltoberfest), zaterdag 28
            september (Gib Mal Gas Party) en zondag 29 september (Hossa Am
            Sonntag) zal het dak er wederom af gaan op de Festwiese in Sibbe -
            Valkenburg. We gaan feesten als nooit tevoren.
            <br />
            <br />
            Und du? Bist du dabei?
          </h2>
        </div>
        <div>
          <StaticImage
            src="../assets/barley.png"
            alt="barley"
            className="w-32 ml-12 my-20"
          />
        </div>
      </div>
    </Fade>
  );
}
