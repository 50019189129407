import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const FB_PIXEL_COOKIE_NAME = `facebook-pixel`;
const COOKIE_NAME = `leeuwbieroktoberfest`;

const CookiePreference = ({
  description,
  label,
  disabled,
  checked,
  defaultChecked,
  onChange,
}: {
  label: string;
  description: string;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  // eslint-disable-next-line no-undef
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="flex flex-row items-start mb-4">
      <div className="flex flex-col">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="cookie_1"
            name="cookie_1"
            value="cookie_1"
            className="mr-4"
            checked={checked}
            disabled={disabled}
            defaultChecked={defaultChecked}
            onChange={onChange}
          />
          <label htmlFor="cookie_1">{label}</label>
        </div>
        <span className="font-extralight mt-2">{description}</span>
      </div>
    </div>
  );
};

type CookieType = {
  version: number;
  default: boolean;
  analytics: boolean;
  marketing: boolean;
};

export const getCookiePreferences = (): CookieType => {
  const cookie = Cookies.get(COOKIE_NAME);

  return cookie ? JSON.parse(cookie) : {};
};

export default function CookieBanner() {
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [showPrefecences, setShowPreferences] = useState(false);
  const [cookies, setCookies] = useState<CookieType>({
    version: 1,
    default: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const cookieAllreadySet = Cookies.get(COOKIE_NAME);
    const fbCookieAllreadySet = Cookies.get(FB_PIXEL_COOKIE_NAME);

    if (!cookieAllreadySet && !fbCookieAllreadySet) {
      setShowCookieBanner(true);
    }
  }, []);

  // cookie already set
  if (!showCookieBanner) {
    return null;
  }

  const storeCookie = (value: CookieType) => {
    Cookies.set(FB_PIXEL_COOKIE_NAME, value.marketing.toString());
    Cookies.set(COOKIE_NAME, JSON.stringify(value), {
      expires: 30,
    });
    setShowCookieBanner(false);
  };

  return (
    <section className="fixed z-[100] max-w-md p-4 mx-auto bg-white border border-gray-200 dark:bg-gray-800 md:right-10 bottom-10 dark:border-gray-700 rounded-2xl">
      <h2 className="font-semibold text-gray-800 dark:text-white">
        🍪 Wij gebruiken cookies
      </h2>
      <p className="mt-4 text-sm text-gray-600 dark:text-gray-300">
        Deze website maakt gebruik van cookies. De essentiële en functionele
        cookies zijn nodig voor het goed functioneren van de website en kunnen
        niet worden geweigerd. Andere cookies worden gebruikt voor statistische
        doeleinden (analytische cookies) en worden enkel geplaatst nadat je een
        keuze gemaakt hebt.{` `}
        <a href="privacyverklaring" className="underline">
          Meer informatie over cookies.
        </a>
      </p>
      {showPrefecences ? (
        <div className="text-gray-600 dark:text-gray-300 text-xs mt-4">
          <CookiePreference
            description="Noodzakelijke cookies helpen een website bruikbaarder te maken,
                door basisfuncties als paginanavigatie en toegang tot beveiligde
                gedeelten van de website mogelijk te maken. Zonder deze cookies
                kan de website niet naar behoren werken."
            label="Essentiële cookies"
            disabled={true}
            defaultChecked={true}
          />
          <CookiePreference
            description="Deze cookies gebruiken we onder meer om te weten hoeveel keer een bepaalde pagina wordt gelezen. Deze informatie gebruiken we enkel om de inhoud van onze website te verbeteren. Deze cookies worden enkel geplaatst nadat u hiervoor uw akkoord hebt gegeven."
            label="Analysecookies"
            defaultChecked={false}
            onChange={(e) =>
              setCookies({ ...cookies, analytics: e.target.checked })
            }
          />
          <CookiePreference
            description="Marketingcookies worden gebruikt om bezoekers te volgen wanneer ze verschillende websites bezoeken. Hun doel is advertenties weergeven die zijn toegesneden op en relevant zijn voor de individuele gebruiker. Deze advertenties worden zo waardevoller voor uitgevers en externe adverteerders."
            label="Marketingcookies"
            defaultChecked={false}
            onChange={(e) =>
              setCookies({ ...cookies, marketing: e.target.checked })
            }
          />
          <button
            onClick={() => storeCookie(cookies)}
            className=" text-xs border text-gray-800 hover:bg-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors focus:outline-none"
          >
            Voorkeuren opslaan
          </button>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 gap-4 mt-4 shrink-0">
            <button
              onClick={() =>
                storeCookie({ ...cookies, analytics: true, marketing: true })
              }
              className=" text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
            >
              Alles toestaan
            </button>

            <button
              onClick={() => setShowPreferences(true)}
              className=" text-xs border text-gray-800 hover:bg-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors focus:outline-none"
            >
              Cookies instellen
            </button>
          </div>
        </>
      )}
    </section>
  );
}
